var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"5fdddc29f3418f86d32160284417968d1513ce9c"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const SENTRY_ENVIRONMENT = process.env.SENTRY_ENVIRONMENT || process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT;

Sentry.init({
    dsn: SENTRY_DSN,
    // Adjust this value in production, or use tracesSampler for greater control

    environment: SENTRY_ENVIRONMENT,
    tracesSampleRate: SENTRY_ENVIRONMENT === "stage" ? 1.0 : .1,
    ignoreErrors: [
        'fbq is not defined',
        "'fbq' is undefined",
        "Can't find variable: fbq",
        "Can't find variable: Components",
        "Components is not defined",
        "Can't find variable: ReactDOM",
        "ReactDOM is not defined",
        "'ReactDOM' is not defined",
        "ReferenceError: Can't find variable: ReactDOM",
        "Can't find variable: google_tag_manager",
        "Failed to fetch",
        "Non-Error promise rejection captured with value: Object Not Found Matching"
    ],
    // Setting this option to true will print useful information to the console while you're setting up Sentry.
    debug: false,
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
});
